import { useAppModalStore } from "@/stores/appModalStore";
import { useAuthStore } from "@/stores/authStore";
import { useTransferWalletStore } from "@/stores/transferWalletStore";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { shallow } from "zustand/shallow";
import useMe from "./fetchers/useMe";
import useLaunch from "./useLaunch";
import usePlatformWallets from "./usePlatformWallets";

type Inputs = {
  amount: string;
};

const schema = yup.object().shape({
  amount: yup.string().required("ກະລຸນາໃສ່ຈຳນວນเงิน"),
});

const fromAndToInfo: {
  [key: string]: {
    label: string;
    title: string;
    submitText: string;
    borderColor: string;
  };
} = {
  main: {
    label: "กระเป๋าหลัก",
    title: "โยกเงินเข้ากระเป๋า UFA",
    submitText: "โยกเงินและเข้าเล่น UFA",
    borderColor: "primary",
  },
  ufa: {
    label: "กระเป๋า UFA",
    title: "โยกเงินเข้ากระเป๋าหลัก",
    submitText: "โยกเงินออกจาก UFA เข้ากระเป๋าหลัก",
    borderColor: "secondary",
  },
};

const useTransferWallet = () => {
  const {
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: { amount: String(0) },
  });

  const { enqueueSnackbar } = useSnackbar();
  const { setGameUrl, setIsIframe } = useLaunch();
  const [closeModal] = useAppModalStore((state) => [state.close], shallow);
  const [user, updateBalance] = useAuthStore(
    (state) => [state.user, state.updateBalance],
    shallow
  );
  const { refresh: refreshWallet, getUfaBalance } = useMe();
  const { refetch } = usePlatformWallets();

  const [fromAndTo, setFromAndTo] = useState<string[]>(["main", "ufa"]);
  const [transfer, loading] = useTransferWalletStore(
    (state) => [state.transfer, state.loading],
    shallow
  );

  const currentFromAndToInfo = fromAndToInfo[fromAndTo[0]];
  const balances: {
    [key: string]: number;
  } = {
    main: Number(user?.balance || 0),
    ufa: Number(user?.ufa_balance || 0),
  };

  const amount = watch("amount");
  const [sliderValue, setSliderValue] = useState(balances[fromAndTo[0]]);

  useEffect(() => {
    setSliderValue(Number(amount));
  }, [amount]);

  const switchFromAndTo = () => {
    setFromAndTo([fromAndTo[1], fromAndTo[0]]);
  };

  const handleTransfer = async (type: string, from: string, to: string) => {
    let fromAndTo = [from, to];
    transfer(
      fromAndTo[0],
      fromAndTo[1],
      (success, data) => {
        refreshWallet();
        if (data?.lobbyUrl) {
          setIsIframe(false);
          setGameUrl(data.lobbyUrl);
        }

        const transactions = [
          { type: "from", ...data.from },
          { type: "to", ...data.to },
        ];

        for (let transaction of transactions) {
          if (transaction.name === "main") {
            updateBalance("balance", transaction.afterBalance);
          } else if (transaction.name === "betflix") {
            updateBalance("betflix_balance", transaction.afterBalance);
          } else if (transaction.name === "ufa") {
            updateBalance("ufa_balance", transaction.afterBalance);
          }
        }

        refetch();
        enqueueSnackbar(success, { variant: "success" });
      },
      (error) => {
        refreshWallet();
        enqueueSnackbar(error, { variant: "error" });
      }
    );
  };

  return {
    control,
    setValue,
    watch,
    handleSubmit,
    errors,
    sliderValue,
    setSliderValue,
    user,
    handleTransfer,
    switchFromAndTo,
    fromAndTo,
    currentFromAndToInfo,
    balances,
    fromAndToInfo,
    loading,
    refreshWallet,
    getUfaBalance,
  };
};

export default useTransferWallet;
