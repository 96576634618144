import * as React from "react";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import { useAppModalStore } from "@/stores/appModalStore";
import { Icon } from "@iconify/react";

export default function DepositAndWithdrawButtonGroup() {
  const [setOpenAppModal, setData] = useAppModalStore((state) => [
    state.setOpen,
    state.setData,
  ]);

  const handleOpenModal = (key: string) => {
    setOpenAppModal(true);
    setData(key);
  };

  return (
    <Grid item md={8} xs={24}>
      <Grid
        container
        style={{
          borderRadius: 10,
          overflow: "hidden",
        }}
        alignItems={"stretch"}
        direction={"row"}
        height={"100%"}
        columns={2}
      >
        <Grid item xs={1} md={1} m={0} p={0}>
          <Button
            startIcon={<Icon icon="lets-icons:up" />}
            size="large"
            variant="contained"
            fullWidth
            sx={{
              background: (theme) =>
                theme.gradient[500] ||
                "linear-gradient(0deg, rgba(3,107,34,1) 0%, rgba(0,255,76,1) 100%)",
              color: "white",
              height: "100%",
              fontSize: 24,
              fontWeight: 700,
              borderRadius: 0,
              textShadow: "1px 1px 4px rgba(0, 0, 0, 0.4)",
            }}
            onClick={() => handleOpenModal("deposit")}
          >
            ຝາກເງິນ
          </Button>
        </Grid>
        <Grid item xs={1} md={1}>
          <Button
            endIcon={<Icon icon="lets-icons:down" />}
            size="large"
            variant="contained"
            fullWidth
            sx={{
              background: (theme) =>
                theme.gradient[600] ||
                "linear-gradient(330deg, rgba(133,6,6,1) 0%, rgba(193,0,0,1) 40%, rgba(255,36,36,1) 100%)",
              color: "white",
              height: "100%",
              fontSize: 24,
              fontWeight: 700,
              borderRadius: 0,
              textShadow: "1px 1px 4px rgba(0, 0, 0, 0.4)",
            }}
            onClick={() => handleOpenModal("withdraw")}
          >
            ຖອນเงิน
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
