"use client";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { usePromotionStore } from "@/stores/promotionStore";
import { shallow } from "zustand/shallow";
import useCampaignList from "@/hooks/fetchers/useCampaignList";
import { useSnackbar } from "notistack";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  FormControl,
  FormHelperText,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
  CardActions,
  Grid,
  TextField,
  CircularProgress,
  Stack,
  Snackbar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import useMe from "@/hooks/fetchers/useMe";
import { useAppModalStore } from "@/stores/appModalStore";
import usePromotionHistory from "@/hooks/fetchers/usePromotionHistory";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function CampaignList() {
  // const { refresh } = useMe();
  // const { refresh: refreshPromotion } = usePromotionHistory();
  // const [accept] = usePromotionStore((state) => [state.accept], shallow);
  const { data: campaignList, isLoading: isCampaignListLoading } =
    useCampaignList();
  const [open, setOpen] = useState(false);
  // const [promotionid, setPromotionId] = useState("");
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [image, setImage] = useState<any>([]);
  // const { enqueueSnackbar } = useSnackbar();
  // const [setAppModalOpen] = useAppModalStore(
  //   (state) => [state.setOpen],
  //   shallow
  // );

  function showDialog(id: string) {
    var options: any = {
      id: `${id}`,
    };

    var filtered = campaignList?.filter(function (obj: any) {
      return Object.keys(options).some(function (key: any) {
        if (key != "color") {
          return obj[key] == options[key];
        } else {
          return options[key].some(function (s: any) {
            return s == obj[key];
          });
        }
      });
    });

    if (filtered != undefined) {
      // setPromotionId(filtered[0].id);
      setName(filtered[0].name);
      setDesc(filtered[0].description);
      setImage(filtered[0].img)
      setOpen(true);
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  if (isCampaignListLoading) {
    return (
      <>
        <Grid sx={{ textAlign: "center" }}>
          <CircularProgress></CircularProgress>
        </Grid>
      </>
    );
  } else {
    return (
      <Box>
        <Box mb={3}>
          <Typography variant="h5" align="center" sx={{ color: (theme) => theme.gradient[900] }}>
            ประกาศรายชื่อผู้โชคดี
          </Typography>
        </Box>
        <Grid
          container
          rowSpacing={3}
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 1, sm: 1, md: 1 }}
        >
          {campaignList?.map((item) => (
            <Grid key={item.id} item xs={1} sm={4} md={4}>
              <Card sx={{ color: (theme) => theme.gradient[900] }}>
                {/* <CardMedia component="img" image={item?.img} alt={item?.name} /> */}
                <CardContent>
                  <Box textAlign="center">
                    <Typography gutterBottom>{item?.name}</Typography>
                    <Button
                      variant="contained"
                      onClick={() => showDialog(item?.id)}
                    >
                      ລາຍລະອຽດ
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <DialogTitle
              id="customized-dialog-title"
              sx={{ m: 0, p: 2, color: (theme) => theme.gradient[900] }}
              textAlign="center"
            >
              {name}
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>

            <DialogContent>
              {
                image.map((url: any) => {
                  return (
                    <CardMedia sx={{ marginBottom: 2 }} key={url} component="img" image={url} alt={name} />
                  )
                })
              }
            </DialogContent>
          </BootstrapDialog>
        </Grid>
      </Box>
    );
  }
}
