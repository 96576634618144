import { Box, Chip, Divider } from "@mui/material";
import React from "react";
import ForgotPasswordForm from "./ChangePasswordForm";

type Props = {};

const ForgotPassword = (props: Props) => {
  return (
    <Box>
      <Divider>
        <Chip
          label="ປ່ຽນລະຫັດຜ່ານ"
          color="primary"
          size="medium"
          style={{ fontSize: "18px", fontWeight: 700 }}
        />
      </Divider>
      <Box p={2} borderRadius={1} mt={2}>
        <Box mt={2}>
          <ForgotPasswordForm />
        </Box>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
