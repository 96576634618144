import * as React from "react";
import { Box, Typography } from "@mui/material";
import ForgotPasswordStepper from "./Stepper";
import StepperContent from "./StepperContent";

export default function ForgotPassword() {
  return (
    <Box p={2}>
      <Box mb={3}>
        <Typography variant="h4" align="center">
          ຍົມລະຫັດພ່ານ
        </Typography>
      </Box>
      <ForgotPasswordStepper />
      <Box mt={2}>
        <StepperContent />
      </Box>
    </Box>
  );
}
