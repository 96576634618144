import * as React from "react";
import Box from "@mui/material/Box";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import * as yup from "yup";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { BankSupport } from "@/@types/bank";
import jsonSupportBanks from "@/@data/supportBanks.json";
import { useForgotPasswordStore } from "@/stores/forgotPasswordStore";
import { shallow } from "zustand/shallow";
import { apiPath } from "@/configs/apiPath";
import http from "@/libs/http";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";

const supportBanks: BankSupport[] = jsonSupportBanks;

type Inputs = {
  bankCode: string;
  bankAccountNumber: string;
  username: string;
};

const schema = yup.object().shape({
  bankCode: yup.string().required("กรุณาเลือกธนาคาร"),
  bankAccountNumber: yup
    .string()
    .min(10, "ເລກບັນຊີธนาคารຕ້ອງມີຢ່າງໜ້ອຍ 10 ຕົວເລກ")
    .max(15, "ເລກບັນຊີธนาคารຕ້ອງມີບໍ່ເກິນ 15 ຕົວເລກ")
    .required("ກະລຸນາໃສ່ເລກບັນຊີธนาคาร"),
  username: yup
    .string()
    .min(10, "ເບີ​ໂທລະ​ສັບศัพท์ຕ້ອງມີຢ່າງໜ້ອຍ 10 ຕົວເລກ")
    .max(10, "ເບີ​ໂທລະ​ສັບศัพท์ຕ້ອງມີບໍ່ເກິນ 10 ຕົວເລກ")
    .required("ກະລຸນາໃສ່ເບີ​ໂທລະ​ສັບศัพท์"),
});

const checkForgotPassword = async ({
  bankCode,
  bankAccountNumber,
  username,
}: {
  bankCode: string;
  bankAccountNumber: string;
  username: string;
}) => {
  try {
    const { data } = await http.post(apiPath.auth.forgotPassword, {
      bank: bankCode,
      account_number: bankAccountNumber,
      username: username,
    });
    return data;
  } catch {
    return false;
  }
};

export default function FormVerifyBankForgotPassword() {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [nextStep, updateInput] = useForgotPasswordStore(
    (state) => [state.nextStep, state.updateInput],
    shallow
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({ resolver: yupResolver(schema) });

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setLoading(true);
    const { bankCode, bankAccountNumber, username } = data;
    const bankAccount = await checkForgotPassword({
      bankCode,
      bankAccountNumber,
      username,
    });
    setLoading(false);
    if (!bankAccount) {
      enqueueSnackbar("ตรวจสอบบัญชีไม่สำเร็จ", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      return;
    }
    if (!bankAccount.status) {
      enqueueSnackbar(bankAccount.msg, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      return;
    }

    // const { fullname: fullName } = bankAccount.data;
    updateInput({ bankCode, bankAccountNumber, username });
    nextStep();
  };

  return (
    <Box p={2} component={"form"} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <FormControl fullWidth>
            <InputLabel id="bankCodeLabel">ธนาคาร</InputLabel>
            <Controller
              name="bankCode"
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange, onBlur } }) => (
                <Select
                  labelId="bankCodeLabel"
                  id="bankCode"
                  value={value}
                  label="ธนาคาร"
                  onBlur={onBlur}
                  onChange={onChange}
                  error={Boolean(errors.bankCode)}
                >
                  {supportBanks.map((bank) => (
                    <MenuItem key={bank.code} value={bank.short_name}>
                      {bank.official_name_th}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            {errors.bankCode && (
              <FormHelperText sx={{ color: "error.main" }}>
                {errors.bankCode.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12}>
          <FormControl fullWidth>
            <Controller
              name="bankAccountNumber"
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange, onBlur } }) => (
                <TextField
                  id="bankAccountNumber"
                  label="ເລກບັນຊີธนาคาร"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  error={Boolean(errors.bankAccountNumber)}
                  placeholder="ເລກບັນຊີธนาคาร"
                />
              )}
            />
            {errors.bankAccountNumber && (
              <FormHelperText sx={{ color: "error.main" }}>
                {errors.bankAccountNumber.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12}>
          <FormControl fullWidth>
            <Controller
              name="username"
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange, onBlur } }) => (
                <TextField
                  id="username"
                  label="ເບີ​ໂທລະ​ສັບศัพท์"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  error={Boolean(errors.username)}
                  placeholder="ເບີ​ໂທລະ​ສັບศัพท์"
                />
              )}
            />
            {errors.username && (
              <FormHelperText sx={{ color: "error.main" }}>
                {errors.username.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12}>
          <LoadingButton
            loading={loading}
            type="submit"
            variant="contained"
            fullWidth
            style={{ height: "50px" }}
          >
            ຢືນຢັນ
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
}
