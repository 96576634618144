"use client";

import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Stack, Theme, useTheme, Typography, Button } from "@mui/material";
import { alpha } from "@mui/system";
import styled from "@emotion/styled";
import { Logo } from "../logo";
import { Announcement } from "@/@types/announcement";
import { AnnouncementCarousel } from "../carousel";
import { useConfig } from "@/hooks";
import usePromotion from "@/hooks/usePromotion";
import { useSnackbar } from "notistack";
import { useAuthStore } from "@/stores/authStore";
import { useAuthModalStore } from "@/stores/authModalStore";
import { shallow } from "zustand/shallow";
import CloseButton from "./CloseButton";
import { useConfigStore } from "@/stores/configStore";
import useSiteSetting from "@/hooks/useSiteSetting";
import FloatingLineContact from "@/sections/FloatingLineContact";

type Props = {
  open: boolean;
  close: () => void;
};

export default function AlertModal({ open, close }: Props) {
  const { lineOaUrl } = useSiteSetting();
  const theme = useTheme();
  const [config] = useConfigStore((state) => [state.config], shallow);
  const { enqueueSnackbar: toast } = useSnackbar();
  const [setOpenLoginModal] = useAuthModalStore(
    (state) => [state.setOpenLoginModal],
    shallow
  );

  const { user } = useAuthStore(
    (state) => ({
      user: state.user,
    }),
    shallow
  );
  const { accept } = usePromotion();
  const { logo } = useConfig();

  const handleGettingPromotion = (id: string | number) => {
    if (!user) {
      close();
      setOpenLoginModal(true);
      return toast("กรุณาเข้าสู่ระบบก่อนกดรับໂປຣໂມຊັນ", { variant: "error" });
    }
    accept({
      id,
      onSuccess: (message: string) => {
        toast(message, { variant: "success" });
      },
      onError: (message: string) => {
        toast(message, { variant: "error" });
      },
    });
  };

  return (
    <Modal
      disableEnforceFocus
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: alpha(theme.palette.secondary.main, 0.7),
            backdropFilter: "blur(18px)",
          },
        },
      }}
    >
      <Content>
        <CloseButton onClick={close} />
        <Stack alignItems={"center"}>
          <Logo src={config?.logo || ""} height={theme.config.logo.height} />
        </Stack>

        <Box display="flex" flexDirection={"column"} alignItems={"center"} mt={2}>
          <Box display="flex" flexDirection={"row"} alignItems={"center"}>
            <Typography variant="h4" fontWeight={"700"} align="center" color={(theme) => theme.gradient[900]}>
              {`⚠️ ประกาศ ⚠️`}
            </Typography>
          </Box>
          <Typography mt={2} variant="h6" lineHeight={1.2} align="center" sx={{ color: (theme) => theme.gradient[900] }}>
            {`กรุณาติดต่อเพื่อขอรับ "บัญชีຝາກ" จากเจ้าหน้าที่`}
          </Typography>
          <Box display="flex" flexDirection={"row"} alignItems={"center"} mt={2}>
            <a href={lineOaUrl} target="_blank">
              <Button
                variant="contained"
                fullWidth
                sx={{
                  fontSize: '1.5rem',
                  fontWeight: 700,
                  color: '#fff',
                  background: 'linear-gradient(0deg, rgba(3, 107, 34, 1) 0%, rgba(0, 255, 76, 1) 100%);',
                  height: "50px",
                  "&:hover": {
                    color: '#fff',
                    background: 'linear-gradient(0deg, rgba(3, 107, 34, 1) 0%, rgba(0, 255, 76, 1) 100%);',
                  },
                }}
              >
                ติดต่อเรา
              </Button>
            </a>
          </Box>
          {/* <CopyButton text={bank.bank_account_no} /> */}
        </Box>
      </Content>
    </Modal>
  );
}

const Content = styled(Box)<{ theme?: Theme }>(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  [theme.breakpoints.up("md")]: {
    width: "800px",
  },
  [theme.breakpoints.up("sm")]: {
    width: "450px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
  height: "auto",
  maxHeight: "80%",
  overflow: "scroll",
  background: theme.gradient[300],
  boxShadow: theme.shadows[6],
  borderRadius: theme.shape.borderRadius,
  border: `2px solid ${alpha(theme.palette.primary.main, 0.05)}`,
  padding: theme.spacing(2),
}));
