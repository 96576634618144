import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button, Stack, Typography, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { StyledCardUserProfile } from "@/components/card/user-profile/styled";
import { formatMoney } from "@/libs/common";

interface Props {
  loading: boolean;
  username: string;
  currentBalance: number;
  isBonus: boolean;
  isPromotionHistoryLoading: boolean;
  promotionHistory: any;
  showFooter?: boolean;
}

interface Props {
  onRemovePro: () => void;
  onOpenModal: (type: string) => void;
}

export default function UserProfile({
  loading = false,
  username,
  currentBalance,
  isBonus,
  isPromotionHistoryLoading,
  promotionHistory,
  showFooter = false,
  onRemovePro,
  onOpenModal,
}: Props) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        borderRadius: "10px",
        overflow: "hidden",
      }}
    >
      <Grid container columns={12}>
        <Grid item xs={12} md={12} sx={{}}>
          <StyledCardUserProfile theme={theme} isbonus={isBonus.toString()}>
            <Stack
              id="profile-container"
              direction="row"
              spacing={2}
              justifyContent={"space-between"}
              alignItems={"start"}
            >
              <Stack
                direction="column"
                spacing={0}
                justifyContent={"center"}
                alignItems={"flex-start"}
              >
                <Typography
                  id="text-username"
                  variant="body2"
                  fontWeight={500}
                  color={theme.palette.primary.main}
                >
                  <span
                    onClick={() =>
                      navigator.clipboard.writeText(username || "")
                    }
                  >
                    USER: {username?.toUpperCase()}
                  </span>
                </Typography>
                <Stack alignItems={"flex-start"}>
                  <Typography
                    id="text-balance-label"
                    variant="body1"
                    fontWeight={600}
                    color={"white"}
                  >
                    ຍອດຍັງເຫຼືອ
                  </Typography>
                  <Typography
                    id="text-balance-value"
                    variant="h3"
                    color={"white"}
                    fontWeight={900}
                  >
                    {formatMoney(currentBalance)}
                  </Typography>
                  <Box sx={{ display: "flex" }} textAlign="left">
                    {promotionHistory ? (
                      <Box
                        border={`1px solid ${theme.palette.secondary.main}`}
                        borderRadius={4}
                      >
                        <Typography
                          variant="caption"
                          color={theme.palette.primary.main}
                        >
                          {isPromotionHistoryLoading ? (
                            "กำลังโหลด"
                          ) : promotionHistory ? (
                            <LoadingButton color="secondary">
                              {promotionHistory?.promotion.name}
                            </LoadingButton>
                          ) : null}
                        </Typography>
                      </Box>
                    ) : (
                      ""
                    )}
                    {promotionHistory && currentBalance <= 5 ? (
                      <Box sx={{ ml: 1 }} borderRadius={16}>
                        {promotionHistory && currentBalance <= 5 ? (
                          <LoadingButton
                            style={{ borderRadius: 16 }}
                            variant="contained"
                            color="secondary"
                            onClick={() => onRemovePro()}
                            loading={loading}
                            sx={{
                              backgroundColor: (theme) => theme.name === 'preset14'
                                ? "#790e50"
                                : "default"
                            }}
                          >
                            ออกจากโปร
                          </LoadingButton>
                        ) : (
                          ""
                        )}
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>
                </Stack>
              </Stack>
            </Stack>
            {showFooter && (
              <Stack bgcolor={(theme) => theme.name === 'preset14' ? "#fb90af" : "#171203"} p={2} direction={"row"} spacing={2}>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => onOpenModal("deposit")}
                  sx={{
                    boxShadow:
                      "0 2px 4px rgba(0,0,0,.12), 0 1px 2px hsla(0,7.3%,92%,.24)",
                    background: (theme) => theme.name === 'preset14'
                      ? "radial-gradient(100% 100% at 50% 0,#fec103 0,#f9890e 100%)"
                      : "radial-gradient(100% 100% at 50% 0,#d1ae4c 0,#64511e 100%)",
                    color: (theme) => theme.name === 'preset14'
                      ? "#fff"
                      : 'default'
                  }}
                  fullWidth
                >
                  ຝາກເງິນ
                </Button>
                <Button
                  size="large"
                  sx={{
                    boxShadow:
                      "0 2px 4px rgba(0,0,0,.12), 0 1px 2px hsla(0,7.3%,92%,.24)",
                    background: (theme) => theme.name === 'preset14'
                      ? "radial-gradient(100% 100% at 50% 0,#30adf2 0,#0e86c7 100%)"
                      : "radial-gradient(100% 100% at 45.61% 0,#d24646 0,#8e3131 100%)",
                  }}
                  onClick={() => onOpenModal("withdraw")}
                  variant="contained"
                  color="secondary"
                  fullWidth
                >
                  ຖອນเงิน
                </Button>
              </Stack>
            )}
          </StyledCardUserProfile>
        </Grid>
      </Grid>
    </Box>
  );
}
