/**
 * สถานะและการกระทำของการลงทะเบียนผู้ใช้งาน
 */
import { apiPath } from "@/configs/apiPath";
import http from "@/libs/http";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { createWithEqualityFn } from "zustand/traditional";

type State = {
  loading: boolean;
  /**
   * ขั้นตอนປະຈຸບັນของการลงทะเบียน
   */
  step: 0 | 1 | 2;
  /**
   * PAYLOAD ที่จะส่งไปยัง API
   */
  input?: {
    bankCode?: string;
    bankAccountNumber?: string;
    username?: string;
    password?: string;
    password_confirmation?: string;
  };
};

type Actions = {
  /**
   * เปลี่ยนไปยังขั้นตอนถัดไปของการลงทะเบียน
   */
  nextStep: () => void;
  /**
   * เปลี่ยนไปยังขั้นตอนก่อนหน้าของการลงทะเบียน
   */
  prevStep: () => void;
  /**
   * อัปเดท INPUT
   */
  updateInput: (input: State["input"]) => void;
  /**
   * ลงทะเบียน
   */
  forgotPassword: (payload: any) => Promise<any>;
  verifyBankAccount: (payload: {
    bankCode: string;
    bankAccountNumber: string;
  }) => Promise<any>;
};

/**
 * สถานะและการกระทำของการลงทะเบียนผู้ใช้งาน
 */
export const useForgotPasswordStore = createWithEqualityFn(
  devtools(
    immer<State & Actions>((set) => ({
      loading: false,
      /**
       * ขั้นตอนປະຈຸບັນของการลงทะเบียน
       */
      step: 0,
      /**
       * เปลี่ยนไปยังขั้นตอนถัดไปของการลงทะเบียน
       */
      nextStep: () => set((state) => ({ step: state.step + 1 })),
      /**
       * เปลี่ยนไปยังขั้นตอนก่อนหน้าของการลงทะเบียน
       */
      prevStep: () => set((state) => ({ step: state.step - 1 })),
      /**
       * อัปเดท INPUT
       */
      updateInput: (input) =>
        set((state) => ({ input: { ...state.input, ...input } })),
      /**
       * ลงทะเบียน
       */
      forgotPassword: async (payload) => {
        set(() => ({ loading: true }));
        try {
          const { data } = await http.post(apiPath.auth.resetPassword, {
            bank: payload.bank,
            account_number: payload.account_number,
            username: payload.username,
            password: payload.password,
            password_confirmation: payload.password_confirmation,
          });
          await new Promise((resolve) => setTimeout(resolve, 2000));
          set(() => ({ loading: false }));
          const { status, msg, data: res_data } = data;
          if (!status) {
            return { status: false, msg: msg };
          }
          return { status: true, msg: msg };
        } catch (err: any) {
          set(() => ({ loading: false }));
          return `ບໍ່ສາມາດແກ້ໃຫ້ລະຫັດຜ່ານໄດ້: ${err.message}`;
        }
      },
      /**
       *
       * ตรวจสอบบัญชีธนาคาร
       */
      verifyBankAccount: async (payload) => {
        set(() => ({ loading: true }));
      },
    }))
  ),
  Object.is
);
