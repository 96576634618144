import * as React from "react";
import { Box, Typography } from "@mui/material";
import SignUpStepper from "@/sections/register/Stepper";
import StepperContent from "@/sections/register/StepperContent";

export default function SignUp() {
  return (
    <Box>
      <Box mb={3}>
        <Typography variant="h4" align="center">
          ສະໝັກສະມາຊິກ
        </Typography>
      </Box>
      <SignUpStepper />
      <Box mt={2}>
        <StepperContent />
      </Box>
    </Box>
  );
}
