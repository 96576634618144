import { useAuthStore } from "@/stores/authStore";
import {
  Box, Chip,
  Divider,
  Typography
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { shallow } from "zustand/shallow";
import { useUserProfile } from "@/hooks";

type Props = {};

const UserProfile = (props: Props) => {
  const [user] = useAuthStore((state) => [state.user], shallow);
  const {
    isLoading,
    currentBalance,
    promotionHistory,
    handleRemovePro,
    isPromotionHistoryLoading,
    // user,
    // isBonus,
    // handleOpenModal,
  } = useUserProfile();

  return (
    <Box>
      {/* <Typography variant="h6">เติมเครดิต</Typography> */}
      <Divider>
        <Chip
          label="ຂໍ້ມູນສ່ວນຕົວ"
          color="primary"
          size="medium"
          style={{ fontSize: "18px", fontWeight: 700 }}
        />
      </Divider>
      {/* <Box mt={2}>
        <Alert severity="info" component={Box}>
          <AlertTitle>ຂັ້ນຕອນການຖອນເງິນ </AlertTitle>
          1). โปรดระบุຈຳນວນเงินที่ต้องการຖອນเครดิต โดยมีขั้นต่ำที่ <br />
          2). กด <strong>"ແຈ້ງຖອນ"</strong> และรอการตรวจสอบจากแอดมิน <br />
        </Alert>
      </Box> */}
      {/* <Box mt={2}>
        <Alert severity="warning">
          <AlertTitle>ຄຳເຕືອນ</AlertTitle>
          ລະບົບຈະໂອນເງິນເຂົ້າບັນຊີທີ່ສະມາຊິກເຄີຍສະໝັກເທົ່ານັ້ນ.
        </Alert>
      </Box> */}
      <Box p={2} borderRadius={1} mt={2} mb={2}>
        <Box display="flex" flexDirection={"column"} alignItems={"center"}>
          <Box
            flex="1"
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
          >
          </Box>
          <Typography
            variant="body1"
            lineHeight={1.5}
            mt={1}
          >
            {user?.bank_name}
          </Typography>
          <Typography variant="body1" lineHeight={0}>
            <Typography component={"span"} color={blue[200]}>
              ໝາຍເລກບັນຊີ{" "}
            </Typography>
            {user?.account_number}
          </Typography>
          <Typography variant="body1" lineHeight={0}>
            <Typography component={"span"} color={blue[200]}>
              ຊື່-ນາມສະກຸນ:{" "}
            </Typography>
            {user?.fullname}
          </Typography>
          <Typography variant="body1" lineHeight={0} onClick={() => navigator.clipboard.writeText(user?.username || '')}>
            <Typography component={"span"} color={blue[200]}>
              ເບີ​ໂທລະ​ສັບ:{" "}
            </Typography>
            {user?.username}
          </Typography>
          <Typography variant="body1" lineHeight={0} onClick={() => navigator.clipboard.writeText(user?.gameusername || '')}>
            <Typography component={"span"} color={blue[200]}>
              ຜູ້ໃຊ້:{" "}
            </Typography>
            {user?.gameusername}
          </Typography>
        </Box>
      </Box>

      <Divider>
        <Chip
          label="ໂປຣໂມຊັນ"
          color="primary"
          size="medium"
          style={{ fontSize: "18px", fontWeight: 700 }}
        />
      </Divider>
      <Box p={2} borderRadius={1} mt={2} mb={2}>
        <Box display="flex" flexDirection={"column"} alignItems={"center"}>
          <Typography sx={{ textAlign: 'center' }} variant="body1" lineHeight={0} onClick={() => { }}>
            <Typography component={"span"} color={blue[200]}>
              ໂປຣໂມຊັນ:{" "}
            </Typography>
            {
              promotionHistory
                ? isPromotionHistoryLoading
                  ? "-"
                  : promotionHistory?.promotion.name
                : "-"
            }
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default UserProfile;
