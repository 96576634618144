"use client";

import React from "react";

import { ContentCopy } from "@mui/icons-material";
import { Button } from "@mui/material";

import { CopyToClipboard } from "react-copy-to-clipboard";

type Props = {
  text: string;
};

const CopyButton = (props: Props) => {
  const [copied, setCopied] = React.useState(false);

  React.useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [copied]);

  return (
    <CopyToClipboard text={props.text}>
      <Button
        startIcon={<ContentCopy />}
        onClick={copied ? () => {} : () => setCopied(true)}
        variant="outlined"
        color="primary"
        size="small"
        style={{ marginTop: 8 }}
      >
        {copied ? "ສຳເນົາแล้ว" : "ສຳເນົາ"}
      </Button>
    </CopyToClipboard>
  );
};

export default CopyButton;
